<template>
  <div class="details">
    <div class="userDetails">
      <div>
        <p class="userDetails__title userDetails__title--first">
          {{ eventUser.name }} {{ eventUser.surname }}
          <span
            class="userDetails__item--green"
            v-if="eventUser.folder_id != null && eventUser.booked != null"
            >Zabookowany</span
          >
        </p>
        <p class="userDetails__item">ID: {{ eventUser.paradiso_id }}</p>
        <p class="userDetails__item">Wiek: {{ eventUser.age }}</p>
        <p class="userDetails__item" v-if="eventUser.folder_id">
          ID teczki: {{ eventUser.folder_id }}
        </p>
      </div>

      <div>
        <p class="userDetails__title">Płatność</p>
        <p class="userDetails__item">
          Łączny koszt: {{ eventUser.price ? eventUser.price : "30zł" }}
        </p>
        <p class="userDetails__item" v-if="calcPayments() != true">
          Brakująca kwota:
          <span class="userDetails__item--red">{{ calcPayments() }}zł</span>
        </p>
        <p class="userDetails__item" v-else>
          Brakująca kwota:
          <span class="userDetails__item--green">Opłacono!</span>
        </p>
        <div class="userDetails__item" v-if="calcPayments() != true">
          <Btn
            :text="`Potwierdź przelew - ${calcPayments()}zł`"
            color="btn--green"
            @click.native="
              payTheRest(1, calcPayments(), 'Potwierdzenie przelewu')
            "
          />
          <Btn
            :text="`Płatność gotówką - ${calcPayments()}zł`"
            color="btn--green"
            @click.native="
              payTheRest(2, calcPayments(), 'Płatność gotówką na miejscu')
            "
          />
        </div>
        <div v-if="eventUser.payments">
          <p class="userDetails__item">Historia wpłat</p>
          <div
            class="userDetails__payments"
            v-for="item in eventUser.payments"
            :key="item.id + 'i'"
          >
            <p class="userDetails__item">
              Typ płatności: {{ item.type_id == 1 ? "Przelew" : "Gotówka" }}
            </p>
            <p class="userDetails__item">Kwota: {{ item.paid_in }} zł</p>
          </div>
        </div>
      </div>

      <div v-if="eventUser.guardian_name">
        <p class="userDetails__title">Opiekun</p>
        <p class="userDetails__item">
          Opiekun: {{ eventUser.guardian_name ? "Tak" : "Nie" }}
        </p>
        <p class="userDetails__item">
          Opiekun na miejscu: {{ eventUser.guardian_id ? "Tak" : "Nie" }}
        </p>
      </div>

      <div v-if="calcPayments() == true">
        <p class="userDetails__title">Bookowanie</p>
        <p class="userDetails__item">
          <Btn
            text="Anuluj"
            color="btn--gray"
            @click.native="$emit('clear', null)"
          />
          <Btn
            text="Potwierdź"
            color="btn--green"
            @click.native="confirmBook()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    paradisoId: String,
    folderId: String,
  },
  computed: {
    ...mapGetters({
      eventUser: "EventUsers/getEventUser",
    }),
  },
  methods: {
    ...mapMutations({
      setcountFolders: "EventUsers/setcountFolders",
    }),
    ...mapActions({
      fetchEventUsersByParadisoId: "EventUsers/fetchEventUsersByParadisoId",
      pay: "EventUsers/pay",
      saveBookUser: "EventUsers/saveBookUser",
    }),
    calcPayments() {
      let sum = 0;
      let userPrice = this.eventUser.price;
      if (this.eventUser.payments) {
        this.eventUser.payments.forEach((elem) => {
          sum = sum + elem.paid_in;
        });
      }
      if (userPrice == null) {
        userPrice = 30;
      }
      console.log(sum);
      if (sum >= parseInt(userPrice)) {
        return true;
      } else {
        return parseInt(userPrice) - sum;
      }
    },
    payTheRest(type_id, paid_id, title) {
      let obj = {
        event_user_id: this.eventUser.id,
        type_id: type_id, // 1 - przelew; 2 - gotowka
        paid_in: paid_id,
        title: title,
      };
      this.pay(obj);
    },
    async confirmBook() {
      let obj = {
        id: this.eventUser.id,
        data: {
          booked: true,
          folder_id: this.folderId,
        },
      };
      const response = await this.saveBookUser(obj);
      if (response.booked == "success") {
        this.setcountFolders(response.folderCount);
        this.$emit("clear", null);
      } else {
        this.setcountFolders(response.folderCount);
        this.$emit("clear", null);
      }
      // else {
      //   // if (response.user) {
      //   //   this.$emit("clear", null);
      //   //   this.$emit("message", 'Uczestnik już zabookowany');
      //   // }
      // }
      console.log(response);
    },
  },
  mounted() {
    this.fetchEventUsersByParadisoId(this.paradisoId);
  },
};
</script>
<style lang="scss" scoped>
.userDetails {
  &__item {
    margin-bottom: 1rem;
    ::v-deep .btn {
      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
    &--green {
      color: $green;
    }
    &--red {
      color: $red;
    }
  }
  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $green;
    margin: 1.5rem -1rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2.5rem;
    &--first {
      margin-top: 0;
    }
  }
  &__payments {
    border-top: 1px solid $border;
    padding-top: 0.5rem;
    padding-left: 1rem;
    margin-left: 1rem;
  }
}
</style>
