<template>
  <div>
    <p>
      {{ title }}
    </p>
    <qrcode-stream
      :camera="camera"
      @decode="onDecode"
      @init="onInit"
      :track="paintBoundingBox"
    >
    </qrcode-stream>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      camera: "auto",
      result: null,
      showScanConfirmation: false,
    };
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters({
      eventUsers: "EventUsers/getEventUsers",
    }),
  },
  methods: {
    ...mapActions({
      fetchEventUsers: "EventUsers/fetchEventUsers",
    }),
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#95ec2f";
        ctx.strokeRect(x, y, width, height);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      console.log(content);
      if (content.includes('folder_')) {
        this.$emit("folder", content);
      } else {
        this.$emit("result", content);
      }
      this.result = content;
      this.pause();
      await this.timeout(500);
      this.unpause();
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  mounted() {
    console.log("ccc");
  },
};
</script>
<style lang="scss" scoped>
.qrcode-stream-wrapper {
  height: 88vh;
}
</style>
