<template>
  <div class="p-scanqr">
    <Camera
      @result="folderId != null ? paradisoId = $event : paradisoId = null"
      @folder="folderId = $event, message = true, countFolders(folderId)"
      :class="{ 'p-scanqr--disable': paradisoId != null }"
    />
    <Details v-if="paradisoId != null" :paradisoId="paradisoId" :folderId="folderId" @clear="paradisoId = $event" @message="message = $event"/>
    <div class="p-scanqr__loader" v-if="message">
      <p v-if="folderId == null && paradisoId == null">Skanuj <span>teczkę!</span></p>
      <p v-else-if="folderId != null && paradisoId == null">Skanuj <span>uczestnika!</span></p>
      <Btn @click.native="message = false" text="OK" color="btn--green" />
    </div>
    <div class="p-scanqr__loader" v-if="getcountFolders == 15">
      <p>Skanuj <span>następną</span> teczkę!</p>
      <Btn @click.native="setcountFolders(0), folderId = null" text="OK" color="btn--green" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Camera from "@/components/scan/Camera.vue";
import Details from "@/components/scan/Details.vue";

export default {
  components: {
    Camera,
    Details,
  },
  data() {
    return {
      paradisoId: null,
      folderId: null,
      message: true,
      foldersCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      eventUsers: "EventUsers/getEventUsers",
      getcountFolders: "EventUsers/getcountFolders",
    }),
  },
  methods: {
    ...mapActions({
      fetchEventUsers: "EventUsers/fetchEventUsers",
      countFolders: "EventUsers/countFolders",
    }),
    ...mapMutations({
      setcountFolders: "EventUsers/setcountFolders",
    }),
  },
  mounted() {
    // if (this.folderId == null) {
    //   this.changeMetaTitle("Owca QR - Skanuj teczkę!");
    // } else {
    //   this.changeMetaTitle("Owca QR");
    // }
    // this.route.meta.title = 'cccc'
    // console.log(this.$route.meta.title = 'ddd');
  },
};
</script>
<style lang="scss" scoped>
.p-scanqr {
  position: relative;
  &--disable {
    display: none;
  }
  &__loader {
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    position: absolute;
    top: -30px;
    left: -30px;
    background: $bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      span {
        color: $green;
      }
    }
  }
}
</style>
